.dashmain {
    background: #F5F5F5;
    max-height: fit-content;
}

.sider {
    width: 280px;
    height: 1008px;


    background: #212529;
    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.02);
}

.linkscon {

    height: 47px;
    width: 247px;


}

.linkscon:hover {
    background: #393939;
    color: #FFC001;
}