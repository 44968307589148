@media (min-width: 50rem) {
    .wrapper {
        display: flex;
    }
}

.example {
    padding: 1rem;
}






.autocomplete {
    position: relative;
    width: 20rem;
}

.autocomplete_dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.autocomplete_search_results_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.autocomplete_search_result {
    padding: 0.75rem 1rem;
    cursor: pointer;
}

.autocomplete_search_result:hover,
.autocomplete_search_result:focus,
.autocomplete_search_result:active {
    background-color: #f9fafc;
}

.tag-list-search {
    width: 20rem;
}

.tag-list {
    margin: 1rem 0;
    padding: 0;
    list-style: none;
}

.tag {
    display: inline-block;
    margin: 0 0.25rem 0.25rem 0;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 2px;
    background-color: #4a4a4a;
    color: #fafafa;
    cursor: pointer;
}

input:focus {
    border-color: #587ef5;
    outline: 0;
}

.tag:hover,
.tag:focus,
.tag:active {
    background-color: #587ef5;
}

.serchin {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;

    border-radius: 10px;
    padding: 5px;
}

.autocompleteserach {
    border: none;
    padding: 0px;
    height: 35px;
    width: 290px;
}