.card {
    width: 100%;
    /* height: 172px; */
    margin-left: 0px;
    margin-right: 3px;


    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.offer {
    border: 1px dashed #2E3E4F;
    width: 129px;
    height: 140px;


    background: #FFFFFF;

    border-radius: 5px;
}

.verimg {
    width: 20px;
    height: 20px;
}

.btn {

    width: 163px;
    height: 48px;
    background: #F0F0F0;
    border: 1px dashed #FFC001;
    border-radius: 5px;
    position: absolute;
}


.btndeal {
    width: 163px;
    height: 48px;
    background: #FFC001;
    border: 1px dashed #FFC001;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
}

.btndeal2 {
    display: none;
    width: 163px;
    height: 48px;
    background: #FFC001;
    border: 1px dashed #FFC001;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
}


.btn::before {
    content: "Get Code";
    text-align: center;
    font-weight: 600;
    position: absolute;
    font-size: 16px;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 163px;
    height: 48px;


    background: linear-gradient(120deg, rgba(255, 193, 7, 1) 0%, rgba(255, 193, 7, 1) 75%, rgba(255, 193, 7, 0) 75%, rgba(255, 193, 7, 0) 100%) border-box;
    transition: 0.5s ease-in-out;

}

.btn:hover::before {
    width: 128px;
}

.coponcode {
    height: 48px;
    border: 1px dashed #FFC001;
    border-radius: 5px;
}

.btn2 {
    display: none;
    width: 163px;
    height: 48px;
    background: #F0F0F0;
    border: 1px dashed #FFC001;
    border-radius: 5px;
    position: relative;
}

.btn2::before {
    content: "Get Code";
    text-align: center;
    font-weight: 600;
    position: absolute;
    font-size: 16px;
    display: flex;
    top: 0;
    align-items: center;
    justify-content: center;
    width: 163px;
    height: 48px;


    background: linear-gradient(120deg, rgba(255, 193, 7, 1) 0%, rgba(255, 193, 7, 1) 75%, rgba(255, 193, 7, 0) 75%, rgba(255, 193, 7, 0) 100%) border-box;
    transition: 0.5s ease-in-out;

}

.btn2:hover::before {
    width: 128px;
}

.copybtn {
    border: none;
    background-color: #ffc107;
    border-color: #ffc107;
    color: #F0F0F0;
    height: 37px;
    width: 79px;
    border-radius: 5px;
}

@media only screen and (max-width: 991px) {

    .btn {
        display: none !important;
    }

    .btn2 {
        display: block !important;
    }

    .btndeal {
        display: none;
    }

    .btndeal2 {
        display: inline;
    }

    .card {
        height: 220px;
    }
}

@media only screen and (max-width: 768px) {


    .card {
        height: 247px;
    }
}

@media only screen and (max-width: 576px) {
    .card {
        height: 100%;
    }

    .offer {
        margin-bottom: 10px;
    }
}