.home {
    background: #F5F5F5;


}

.hero {
    width: 100%;



    background: #FECE3E;
    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
}

.heroinpur {
    display: none;

}

.herosec {
    /* height: 400px; */



    background: linear-gradient(180deg, #F5F5F5 90%, rgba(245, 245, 245, 1) 90%);
}

.imageWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardstore {
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    width: 176px;
    height: 179px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 200px;
    /* margin: 100px auto; */
    position: relative;
    overflow: hidden;
}



.cardstoreimg {
    width: 85%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
}

.cardstore:hover .overlay {
    opacity: 1;
    transform: scale(1);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 0, 0);
    /* Adjust the overlay color as needed */
    color: #ffffff;
    /* Adjust the text color as needed */
    opacity: 0;
    transition: opacity 0.3s ease;
}

.overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* font-weight: bold; */
    /* font-size: 5em; */
    color: #fff;
    text-align: center;
}

.cardstore:hover img {
    transform: scale(1.1);
}

.storehold {
    width: 63px;
    height: 63px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.storehold img {
    max-width: 90%;
    max-height: 100%;
    object-fit: contain;
}

.comsd {
    width: 130px;
    /* overflow: hidden; */
}

.storeimg {
    max-width: 100%;
    max-height: 100%;
}

.backlogosec {

    height: 238px;


    background: #FFFFFF;
    border-radius: 5px;
    background-image: url(../../assets/RLOGO0-01\ 2.svg);
    background-repeat: no-repeat;
    background-size: 558px;
    background-position: -127px -141px;
}

.backlogoinput {
    width: 100%;
    height: 54px;

    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}

.backlogobtn {
    width: 196px;
    height: 54px;
    color: #FFFFFF;

    background: #FFC001;
    border: 1px solid #FFC001;
}

@media only screen and (max-width: 768px) {
    .cardstore {
        width: 120px;
        height: 120px;
    }

    .heroinpur {
        display: block;
        border: none;
        height: 61px;
        width: 100%;
        background: #FECE3E;
        box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.02);
        border-radius: 5px;
        padding: 10px;

    }

    .hero {
        display: none !important;
    }

    /* 
    .mobile {
        display: block;
    } */
}