.main {



    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.input1 {
    width: 100%;
    height: 47px;


    background: #F8FAFC;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.input2 {
    width: 100%;
    height: 47px;
    left: calc(50% - 275px/2 + 137.5px);
    top: 138px;

    background: #F8FAFC;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.input3 {
    width: 100%;
    height: 47px;


    background: #F8FAFC;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.btn {
    width: 100%;
    height: 47px;

    border: none;
    background: #FEC02E;
    border-radius: 5px;
}

.titb {
    background: #F8F9FA;
    border-bottom: 1px solid #D0D0D0;

}

.uploadbtn {
    width: 171px;
    height: 47px;


    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}