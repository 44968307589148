.Couponcard {
    width: 279px;
    height: 285px;


    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    cursor: pointer;
}

.imgccon {
    width: 140px;
    height: 93px;
    /* background-color: grey; */
}

.cardbtn {
    width: 100%;
    height: 40px;

    border: none;
    background: #FFC001;
    border-radius: 5px;
}

.cardbtn:hover {

    background: #FFD862;

}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
}

.imageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.container img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}