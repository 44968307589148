.fom1 {
    width: 100%;


    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.hr {
    border-bottom: 1px solid #D0D0D0;
}

.inputd {
    width: 100%;
    height: 40px;


    background: #F8FAFC;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.inputt {
    width: 100%;
    height: 234px;


    background: #F8FAFC;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.imgs {
    width: 109px;
    height: 109px;


    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.imgsw {
    width: 100%;
    height: 100%;


    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.uploadbtn {
    width: 171px;
    height: 40px;


    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.savebtn {
    width: 100%;
    height: 47px;

    border: none;
    background: #FEC02E;
    border-radius: 5px;
}

.upload-btn {
    display: none;
}

.upload-btn-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.upload-btn-label:hover {
    background-color: #45a049;
}

.upload-btn-label::after {
    content: 'Select File';
}

.upload-btn-input {
    display: none;
}

.upload-btn-input+label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.upload-btn-input+label:hover {
    background-color: #45a049;
}

.upload-btn-input+label::after {
    content: 'Select File';
}

.file-name {
    margin-top: 10px;
    font-size: 14px;
    color: #555555;
}