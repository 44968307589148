body {
  margin: 0;
  font-family: 'Poppins';

}

.fontw1 {
  font-weight: 100;
}

.fontw2 {
  font-weight: 200;
}

.fontw3 {
  font-weight: 300;
}

.fontw4 {
  font-weight: 400;
}

.fontw5 {
  font-weight: 500;
}

.fontw6 {
  font-weight: 600;
}

.fontw7 {
  font-weight: 700;
}

.fontw8 {
  font-weight: 800;
}


.fontw9 {
  font-weight: 900;
}

.font12 {
  font-size: 12px;
}

.font13 {
  font-size: 13px;
}

.font14 {
  font-size: 14px;
}

.font15 {
  font-size: 15px;
}

.font16 {
  font-size: 16px;
}

.font17 {
  font-size: 17px;
}

.font18 {
  font-size: 18px;
}

.font19 {
  font-size: 19px;
}

.font20 {
  font-size: 20px;
}

.font20-md {
  font-size: 20px;
}

.font21 {
  font-size: 21px;
}

.font22 {
  font-size: 22px;
}

.font23 {
  font-size: 23px;
}

.font24 {
  font-size: 24px;
}

.font25 {
  font-size: 25px;
}

.font26 {
  font-size: 26px;
}

.font27 {
  font-size: 27px;
}

.font28 {
  font-size: 28px;
}

.font28-md {
  font-size: 28px;
}

.font29 {
  font-size: 29px;
}

.font30 {
  font-size: 30px;
}

.font30-md {
  font-size: 30px;
}

.font31 {
  font-size: 31px;
}

.font32 {
  font-size: 32px;
}

.font33 {
  font-size: 33px;
}

.font34 {
  font-size: 34px;
}

.font35 {
  font-size: 35px;
}

.font36 {
  font-size: 36px;
}

.font37 {
  font-size: 37px;
}

.font38 {
  font-size: 38px;
}

.font39 {
  font-size: 39px;
}

.font40 {
  font-size: 40px;
}

.font50 {
  font-size: 50px;
}

.font150 {
  font-size: 150px;
}

.me-50 {
  margin-right: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-md-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-md-70 {
  margin-bottom: 70px;
}

.me-100 {
  margin-right: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.me-md-100 {
  margin-right: 100px;
}

.pe-50 {
  padding-right: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-md-50 {
  padding-bottom: 50px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-md-70 {
  padding-bottom: 70px;
}

.pe-100 {
  padding-right: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pe-md-100 {
  padding-right: 100px;
}

.an {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.an.active {
  color: #FFC001 !important;
  /* Active link color (yellow) */
}

.an2 {
  text-decoration: none;
  cursor: pointer;
}

.wit {
  color: white;
}

.D9 {
  color: #9D9D9D;
}

.D73 {
  color: #737373;
}

.C71 {
  color: #16C716;
}

.red {
  color: lightcoral;
}

.DF {
  color: #0D6EFD;
}

.B1 {
  color: #B1B1B1;
}

.fe {
  color: #fece3e;
}

.page404 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page404in {
  font-family: raleway, sans-serif;
  width: 100%;
  height: 40px;
  padding: 3px 15px;
  color: #222;
  font-size: 18px;
  background: #f8fafb;
  border: 1px solid rgba(34, 34, 34, .2);
  border-radius: 3px;
}

.page404con {
  display: flex;
  justify-content: center;

}

.page404btn {
  font-family: raleway, sans-serif;

  width: 120px;
  height: 40px;
  text-align: center;
  border: none;
  background: #fece3e;
  cursor: pointer;
  padding: 0;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  border-radius: 3px;
}

.page404a {
  font-family: raleway, sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 15px;
  text-decoration: none;
  color: #39b1cb;
}

.liasa {
  list-style: none;
  color: #ECECEC;
  margin: 0px !important;
  padding: 0px;
}

@media only screen and (max-width: 768px) {

  .me-md-100 {
    margin-right: 0px !important;
  }

  .font28-md {
    font-size: 20px;
  }
}


.hr {
  border-bottom: 1px solid #ECECEC;
}

.lh {
  line-height: 40px;
}

.upload-btn {
  display: none;
}

.upload-btn-label {
  width: 171px;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  border-radius: 5px;
  color: black
}

.upload-btn-label:hover {
  /* background-color: #45a049; */
}


.inputri {
  height: auto !important;
  width: auto !important;
  display: inline !important;
}

.upload-btn-input {
  display: none;
  content: "asas";
}

.upload-btn-input::after {
  content: "asas";
}

.upload-btn-input+label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FFFFFF;
  /* color: #ffffff; */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  color: black;
  text-align: center;
}

.upload-btn-input+label:hover {
  /* background-color: #45a049; */
}



.file-name {
  margin-top: 10px;
  font-size: 14px;
  color: #555555;
}

.custom-rating {
  width: 115px;
}

.star {
  color: gray;
  cursor: pointer;
  transition: color 0.3s;
}

.star.filled {
  color: gold;
}

@media only screen and (max-width: 768px) {
  .mt-md-70 {
    margin-top: 0px;
  }

  .mb-md-70 {
    margin-bottom: 0px;
  }

  .pb-md-70 {
    padding-bottom: 0px;
  }

  .font20-md {
    font-size: 16px;
  }

  .font30-md {
    font-size: 20px;
  }

  .pb-md-50 {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 363px) {
  .custom-rating {
    margin-right: 23px;
    width: 115px;
  }

}