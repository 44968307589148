.storemain {
    background: #F5F5F5;
}

.maimg {
    width: 240px;
    height: 235px;


    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maimg img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.cardstoreimg {
    width: 85%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
}

.storebtn {
    width: 240px;
    height: 49px;


    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 10px;
}

.storedetails {
    width: 240px;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 10px;
}

.hr {
    border-bottom: 1px solid #EAEAEA;
}

.baner {
    width: 240px;



    background: #BDBDBD;
}

.cardstore {
    height: 68px;
    width: 68px;

    border: 1px solid #D0D0D0;

    border-radius: 10px;
    /* margin: 100px auto; */
    position: relative;
    overflow: hidden;
}



.cardstoreimg {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    ;

    height: 100%;
    ;
    border-radius: 10px;
    transition: transform .8s ease;

}

.cardstore:hover .overlay {
    opacity: 1;
    transform: scale(1);
}

.overlay {
    position: absolute;
    background-color: rgba(255, 0, 0);
    width: 100%;
    height: 100%;
    top: 0;
    transform: scale(1.3);
    opacity: 0;
    transition: transform .4s ease 0s;
}

.overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* font-weight: bold; */
    /* font-size: 5em; */
    color: #fff;
    text-align: center;
}

.cardstore:hover img {
    transform: scale(1.1);
}

.infocon {
    width: 100%;
    /* height: 94px; */


    background: #2E3E4F;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.infoin {
    width: 329px;
    height: 46px;


    background: #FFFFFF;
    border-radius: 10px;
}

.infobtn {
    width: 173px;
    height: 46px;
    border: none;

    background: #FFC001;
    border: 1px solid #000000;
    border-radius: 10px;
}

.inebt {
    width: 278px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #a39c9c;
    padding: 5px;
}

.sbtn {
    width: 173px;
    height: 46px;
    border: none;

    background: #FFC001;

    border-radius: 10px;
}

.mobile {
    display: none;
}

.maimg2 {
    width: 125px;
    height: 133px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.maimg2 img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.inputinfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 769px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .storebtn {
        width: 100%;
    }

    .inputinfo {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }

    .maimg2 {
        width: 100px !important;
        height: 100px !important;
    }

    .infocon {
        width: 100%;
        /* height: 94px; */

        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        background: #2E3E4F;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
    }
}

@media only screen and (max-width: 320px) {
    .inputinfo {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
    }

    .infoin {
        width: 100%;
        height: 46px;


        background: #FFFFFF;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .infobtn {
        width: 100%;
        height: 46px;
        border: none;

        background: #FFC001;
        border: 1px solid #000000;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 375px) {
    .storebtn {
        width: 100%;
    }

    .maimg2P {
        width: 50% !important;

    }

    .maimg4 {
        width: 50% !important;
    }
}

@media only screen and (max-width: 320px) {
    .maimg2 {
        width: 100px !important;
        height: 100px !important;
    }
}