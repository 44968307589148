.maindiv{
    
height: 353px;
flex-shrink: 0;
border-radius: 10px;
background: rgba(255, 206, 49, 0.20);
}
.outerDiv{
    
}
.expbtn{
    border: none;
    width: 172px;
height: 43px;
flex-shrink: 0;
    border-radius: 5px;
background: #FECE3E;
box-shadow: 0px 4px 42px 0px rgba(0, 0, 0, 0.02);
}
.bannerimg{
   
    width:300px !important;
height: 100%;
/* flex-shrink: 0; */
}
.imgContiner{
    
    background-image: url(../../assets/bgraphic.png);
    height: 353px;
    background-repeat: no-repeat;
    background-position:right;
    background-size: contain;
}
.croHeading{
    color: #252525;
font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.croParagrap{
    color: #252525;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
@media only screen and (max-width: 767px) {
    .maindiv{
    
        height: 553px;
        flex-shrink: 0;
        border-radius: 10px;
        background: rgba(255, 206, 49, 0.20);
        }
        .imgContiner{
            height: 353px !important;
            background-image: url(../../assets/bgraphic.png);
            height: 50%;
            background-repeat: no-repeat;
            background-position:center;
            background-size: contain;
        }
        .croHeading{
            color: #252525;
        font-family: Poppins;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        }
        .croParagrap{
            color: #252525;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        }
  }
  @media only screen and (max-width:425px) {
    .maindiv{
    
        height: 498px;
        flex-shrink: 0;
        border-radius: 10px;
        background: rgba(255, 206, 49, 0.20);
        }
        .imgContiner{
    height: 255px    !important;
            background-image: url(../../assets/bgraphic.png);
            height: 40%;
            background-repeat: no-repeat;
            background-position:center;
            background-size: contain;
        }
  }
  @media only screen and (max-width:320px) {
    .maindiv{
    
        height: 526px;
        flex-shrink: 0;
        border-radius: 10px;
        background: rgba(255, 206, 49, 0.20);
        }
        .imgContiner{
    height: 255px    !important;
            background-image: url(../../assets/bgraphic.png);
            height: 40%;
            background-repeat: no-repeat;
            background-position:center;
            background-size: contain;
        }
  }