.Navbarbtn {
    width: 74px;
    height: 40px;
    border: none;

    background: #FFC001;
    border-radius: 5px;
}

.serchin {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;

    border-radius: 10px;
    padding: 5px;
}

.autocompleteserach {
    border: none;
    padding: 0px;
    height: 35px;
    width: 290px;
}

.autocompleteserach:focus {
    border: #ffffff00;
}

.ulnav {
    display: flex;
    text-decoration: none;
    list-style: none;
}

.navlinds {
    text-decoration: none;
    color: black;
}

.navlinds:hover {
    color: #FFC001 !important;
    text-decoration: none;
}

.activeLink {
    color: #FFC001 !important;
    text-decoration: none;
}

.Desktop {
    position: sticky;
    top: 0px;
    background: #FFFFFF;
    z-index: 999;
}

.Mobile {
    display: none !important;
}

@media only screen and (max-width: 991px) {

    .Desktop {
        display: none !important;
    }

    .Mobile {
        display: block !important;
    }
}

.mobsider {

    transition: width 0.3s;
    background-color: #FFFFFF;
    position: absolute;
    overflow: hidden;
    top: 0px;
    width: 100px;
    height: 100%;
    z-index: 9;
}

.mobsiderlist {

    width: 250px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Change the opacity value to control darkness */
    z-index: 5;
    /* Make sure the overlay is on top of other elements */
}

* {
    box-sizing: border-box;
}


@media (min-width: 50rem) {
    .wrapper {
        display: flex;
    }
}

.example {
    padding: 1rem;
}







.autocomplete {
    position: relative;
    width: 20rem;
}

.autocomplete_dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.autocomplete_search_results_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.autocomplete_search_result {
    padding: 0.75rem 1rem;
    cursor: pointer;
}

.autocomplete_search_result:hover,
.autocomplete_search_result:focus,
.autocomplete_search_result:active {
    background-color: #f9fafc;
}

.tag-list-search {
    width: 20rem;
}

.tag-list {
    margin: 1rem 0;
    padding: 0;
    list-style: none;
}

.tag {
    display: inline-block;
    margin: 0 0.25rem 0.25rem 0;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 2px;
    background-color: #4a4a4a;
    color: #fafafa;
    cursor: pointer;
}

.tag:hover,
.tag:focus,
.tag:active {
    background-color: #587ef5;
}