.fom1 {
    width: 100%;


    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.hr {
    border-bottom: 1px solid #D0D0D0;
}

.inputd {
    width: 100%;
    height: 40px;


    background: #F8FAFC;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.inputt {
    width: 100%;
    height: 234px;


    background: #F8FAFC;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.imgs {
    width: 109px;
    height: 109px;


    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.uploadbtn {
    width: 171px;
    height: 40px;


    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.savebtn {
    width: 100%;
    height: 47px;

    border: none;
    background: #FEC02E;
    border-radius: 5px;
}