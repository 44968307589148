.storemain {
    background: #F5F5F5;
    min-height: 73vh;
}

.storedetails {
    width: 240px;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 10px;
}

.hr {
    border-top: 1px solid #ECECEC;

}

.store {
    width: 100%;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 10px;
}

.storecon {
    border-bottom: 1px solid #ECECEC;
}

.imgtag {

    height: 132px;
    border-right: 1px solid #ECECEC;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imgtag img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
}

.linntn {
    width: 163px;
    height: 48px !important;
    padding: 10px 30px 10px 30px;
    color: black;
    text-decoration: none;
    background: transparent;
    border: 1px solid #212529 !important;
    border-radius: 0.25rem
}

.linntn2 {
    display: none;
    width: 163px;
    /* padding: 9px 0px 0px 30px; */
    color: black;
    text-decoration: none;
    height: 48px;
    background: transparent;
    border: 1px solid #212529 !important;
    border-radius: 0.25rem
}

@media only screen and (max-width: 768px) {
    .linntn {
        display: none;

    }

    .linntn2 {
        display: block;

    }

    .storedetails {
        width: 100%;
    }
}